import React, { CSSProperties, PropsWithChildren } from "react"
import styled, { IntrinsicElementsKeys } from "styled-components"

interface FlexProps {
    direction?: "column" | "row"
    gap?: number
    justifyContent?: CSSProperties["justifyContent"]
    alignItems?: CSSProperties["alignItems"]
    as?: IntrinsicElementsKeys
    className?: string
    style?: CSSProperties
}

export const Flex = ({
    direction: flexDirection = "row",
    gap = 0,
    justifyContent = "flex-start",
    alignItems = "flex-start",
    as = "div",
    className = "",
    style = {},
    children,
}: PropsWithChildren<FlexProps>) => {
    return React.createElement(as, {
        style: {
            display: "flex",
            flexDirection,
            gap: `${gap}rem`,
            justifyContent,
            alignItems,
            ...style,
        },
        className,
        children,
    })
}
