import React, { PropsWithChildren } from "react"
import styled from "styled-components"
import classNames from "classnames"

const Host = styled.div`
    max-width: ${({ theme }) => theme.maxWidth}rem;
    margin: auto;
    width: 100%;
`

interface ConstrainedProps {
    className?: string
}

export const Constrained = ({
    children,
    className = "",
}: PropsWithChildren<ConstrainedProps>) => {
    return (
        <Host className={classNames("ShoutConstrained-root", className)}>
            {children}
        </Host>
    )
}
