import { Page } from "../../shared/Page/Page"
import { SEO } from "../../utils/SEO"
import React from "react"
import styled from "styled-components"
import { HeaderTop } from "../../shared/Header/HeaderTop"
import { HeaderBottom } from "../../shared/Header/HeaderBottom"
import { Constrained } from "../../shared/Constrained/Constrained"
import { Footer } from "../../shared/Footer/Footer"
import { Flex } from "../../shared/Flex/Flex"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { blue, blueGrey } from "../../utils/colors"
import { GatsbyImage } from "gatsby-plugin-image"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import { IconChevronLeft, IconLink } from "@tabler/icons"
import { transparentize } from "polished"
import { Link } from "gatsby"
import media from "css-in-js-media"
import { HeaderMobile } from "../../shared/Header/HeaderMobile"

const Container = styled.div`
    .bg {
        margin: 0 -20rem;
        padding: 0 20rem 60rem;
    }

    .story-layout {
        max-width: 1150rem;
        margin: 0 auto;
    }

    .blurb {
        font-family: Gilroy-Bold, sans-serif;
        text-align: center;
        font-size: 40rem;
        line-height: 160%;
        padding: 60rem 20rem;
        margin: 20rem auto 60rem;
        width: 100%;
        max-width: 1150rem;
        border-radius: 20rem;
        position: relative;
        overflow: hidden;
        box-shadow: rgba(17, 12, 46, 0.15) 0 48rem 100rem 0;

        .overlay {
            background: linear-gradient(
                45deg,
                ${transparentize(0.05, blueGrey[900])},
                ${transparentize(0.05, blueGrey[700])}
            );
            position: absolute;
            inset: 0;
        }

        .ShoutConstrained-root {
            max-width: 700rem;
            z-index: 10;
            position: relative;
            padding: 50rem;
            border-radius: 10rem;
            border: 3rem solid white;
        }

        .background-image {
            position: absolute;
            inset: 0;
            z-index: 0;
        }
    }

    .layout {
        .article {
            flex: 1;

            .title {
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 46rem;
                line-height: 160%;
            }

            .leading {
                font-family: "Gilroy-SemiBold", sans-serif;
                font-size: 20rem;
                line-height: 36rem;
                margin-bottom: 40rem;
            }

            .body {
                font-family: "Inter", sans-serif;
                //font-size: 20rem;
                //line-height: 36rem;
                font-size: 16rem;
                line-height: 28rem;
            }
        }

        .sidebar {
            flex: 1;
            max-width: 400rem;
            padding-left: 40rem;
            border-left: 1rem solid ${blueGrey[200]};
            margin-left: 40rem;

            .box {
                position: sticky;
                top: 20rem;
                display: flex;
                flex-direction: column;
                gap: 20rem;
                border-radius: 8rem;
                justify-content: center;
                align-items: stretch;
                //box-shadow: ${transparentize(
                    0.8,
                    blueGrey[300]
                )} 0 8rem 24rem;
                // background: ${blueGrey[50]};
                box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
                    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
                overflow: hidden;
                padding-bottom: 20rem;

                .logo-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 30rem 20rem;
                    background: #fff;
                    border-bottom: 2rem solid ${blue[700]};

                    .logo {
                        width: 60%;
                    }
                }

                .item {
                    padding: 0 20rem;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    gap: 5rem;

                    .title {
                        font-size: 12rem;
                        font-family: Inter, sans-serif;
                        font-weight: 600;
                        color: ${blueGrey[700]};
                    }

                    .content {
                        font-size: 13rem;
                        color: ${blueGrey[500]};
                        line-height: 170%;
                    }
                }
            }
        }
    }

    ${media("<desktop")} {
        .layout {
            flex-direction: column !important;

            .sidebar {
                max-width: unset;
                margin-left: 0;
                padding-left: 0;
                border-left: none;

                margin-top: 40rem;
                padding-top: 40rem;
                border-top: 1rem solid ${blueGrey[200]};
            }
        }

        .blurb {
            padding: 10rem;

            .ShoutConstrained-root {
                font-size: 20rem;
                line-height: 160%;
                font-family: Gilroy-SemiBold, sans-serif;
            }
        }
    }
`

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            return (
                <>
                    <h2>Embedded Asset</h2>
                    <pre>
                        <code>{JSON.stringify(node, null, 2)}</code>
                    </pre>
                </>
            )
        },
    },
}

const CustomerStory = ({ location, ...props }) => {
    const context = props.pageContext
    const palette = blueGrey

    return (
        <Page location={location} disableCta disableHeader disableFooter>
            <SEO
                location={location}
                title={`${context.companyName}'s story with 23shout`}
                description={""}
            />
            <Container>
                <div className={"bg"}>
                    <Constrained>
                        <HeaderTop />
                        <HeaderBottom />
                        <HeaderMobile />
                    </Constrained>

                    <div
                        className={"blurb"}
                        style={{
                            background: palette[800],
                            color: blueGrey[50],
                        }}
                    >
                        <GatsbyImage
                            className={"background-image"}
                            image={context.backdropImage.gatsbyImageData}
                            alt={context.backdropImage.description}
                            objectPosition={"center"}
                        />
                        <div className={"overlay"} />
                        <Constrained>{context.blurb}</Constrained>
                    </div>

                    <Constrained className={"story-layout"}>
                        <Flex className={"layout"} alignItems={"stretch"}>
                            <div className={"article"}>
                                <div
                                    className="title"
                                    style={{ color: palette[800] }}
                                >
                                    {context.title}
                                </div>
                                <Flex
                                    gap={10}
                                    style={{ margin: "10rem 0 50rem" }}
                                >
                                    <Link to={"/stories"}>
                                        <FlatButton
                                            variant={"outlined"}
                                            color={"primary"}
                                        >
                                            <IconChevronLeft /> Back to stories
                                        </FlatButton>
                                    </Link>
                                    <a href={context.websiteUrl}>
                                        <FlatButton
                                            variant={"outlined"}
                                            color={"primary"}
                                        >
                                            <IconLink /> Visit{" "}
                                            {context.companyName}'s website
                                        </FlatButton>
                                    </a>
                                </Flex>
                                <div
                                    className={"leading"}
                                    style={{ color: palette[600] }}
                                >
                                    {renderRichText(
                                        context.leadingText,
                                        options
                                    )}
                                </div>
                                <div
                                    className={"body"}
                                    style={{ color: palette[500] }}
                                >
                                    {renderRichText(
                                        context.testimonialBody,
                                        options
                                    )}
                                </div>
                            </div>
                            <div className={"sidebar"}>
                                <div className={"box"}>
                                    <div className={"logo-box"}>
                                        {!context.logoUrl.gatsbyImageData ? (
                                            <img
                                                src={context.logoUrl.file.url}
                                                alt={`${context.companyName}'s logo`}
                                                className={"logo"}
                                            />
                                        ) : (
                                            <GatsbyImage
                                                alt={`${context.companyName} logo`}
                                                image={
                                                    context.logoUrl
                                                        .gatsbyImageData
                                                }
                                                className={"logo"}
                                            />
                                        )}
                                    </div>
                                    <div className={"item"}>
                                        <div className={"title"}>About</div>
                                        <div className={"content"}>
                                            {renderRichText(
                                                context.whatTheyDo,
                                                options
                                            )}
                                        </div>
                                    </div>
                                    <div className={"item"}>
                                        <div className={"title"}>
                                            What changed
                                        </div>
                                        <div className={"content"}>
                                            {renderRichText(
                                                context.whatChanged,
                                                options
                                            )}
                                        </div>
                                    </div>
                                    <div className={"item"}>
                                        <div className={"title"}>Founded</div>
                                        <div className={"content"}>
                                            {context.dateFounded}
                                        </div>
                                    </div>
                                    <div className={"item"}>
                                        <div className={"title"}>Location</div>
                                        <div className={"content"}>
                                            {context.hqLocation}
                                        </div>
                                    </div>
                                    <div className={"item"}>
                                        <a href={context.websiteUrl}>
                                            <FlatButton
                                                variant={"outlined"}
                                                color={"primary"}
                                                fullWidth
                                                justify={"center"}
                                                size={"large"}
                                            >
                                                Visit website
                                            </FlatButton>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Flex>
                    </Constrained>
                </div>

                <Constrained>
                    <Footer />
                </Constrained>
            </Container>
        </Page>
    )
}

export default CustomerStory
